import {LANGUAGES_KEYS, COMMON_LANGUAGES, ROUTES, DEFAULT_LANGUAGE} from './src/const'

export const onClientEntry = () => {
  const {pathname} = window.location
  const pathnameRegExp = new RegExp(
    `^(/(${Object.keys(LANGUAGES_KEYS).join('|')}))?(${Object.values(ROUTES).join('|')}|/404/)$`
  )

  if (!pathnameRegExp.test(pathname)) {
    const langRegExp = new RegExp(`^/(${COMMON_LANGUAGES.join('|')})/.*`)
    const lang = langRegExp.test(pathname) ? String(pathname).slice(1, 3) : DEFAULT_LANGUAGE
    window.location.replace(`/${lang}/404/`)
  }
}
